/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    img: "img",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    className: "c-md-h1",
    id: "what-causes-excessive-ear-wax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-excessive-ear-wax",
    "aria-label": "what causes excessive ear wax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What causes excessive ear wax"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/earwax-hero.png",
    alt: "Leaves background hero",
    className: "c-md-img"
  })), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "commonly-asked-questions-about-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#commonly-asked-questions-about-earwax",
    "aria-label": "commonly asked questions about earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Commonly asked questions about earwax"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Every person produces earwax."), " Without it, your ear canals can’t function properly. However, too much of it can cause problems. If you think you have excessive earwax, there are a few key facts you need to know. For instance, you should know how to safely remove earwax at home, and what to expect if you need to go to the hospital."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-excess-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-excess-earwax",
    "aria-label": "what causes excess earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Causes Excess Earwax?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Some people produce more earwax than others. However, according to the NHS, there are other factors that can play a part, including the production of particularly hard wax, age, bony growths in the outer ear, and having narrow or hairy ear canals."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A lot of people make the ", React.createElement(_components.strong, null, "mistake of cleaning out the wax in their ears with Q-tips"), ". This method can help remove small pieces of excess earwax, but it could also push wax deeper into the ear canal — making it much more difficult to remove."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "spotting-the-signs-of-excessive-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#spotting-the-signs-of-excessive-earwax",
    "aria-label": "spotting the signs of excessive earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Spotting the signs of excessive earwax"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The NHS website lists several ", React.createElement(_components.strong, null, "symptoms of excess earwax build-up"), ". You may be experiencing one or all of these symptoms, but it is important not to jump to conclusions or self-diagnose. Only a doctor will be able to give you an accurate diagnosis."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "The symptoms relating to excess earwax build-up include:")), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing loss (", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Earache (", React.createElement(_components.a, {
    href: "/resources/hearing-loss/earache/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Itchiness — in or around the ear (", React.createElement(_components.a, {
    href: "/useful-knowledge/itchy-ears/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus — hissing or whistling sounds coming from within your head (", React.createElement(_components.a, {
    href: "/resources/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Vertigo — dizziness or a spinning sensation (", React.createElement(_components.a, {
    href: "/resources/hearing-loss/bppv/",
    className: "c-md-a"
  }, "more information"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear infections"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-do-you-clean-excess-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-do-you-clean-excess-earwax",
    "aria-label": "how do you clean excess earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How do you clean excess earwax?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Earwax is a protective substance, so it is good to have a healthy amount in your ear canals."), " If earwax is causing any of the symptoms listed above, there are a few preventative measures you can take."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to WebMD, mineral oil and a solution of warm water and a little hydrogen peroxide can help. But keep in mind, you should not do anything until you have consulted your doctor. Avoid the temptation to use Q-tips to remove earwax, as they can push it further into your ear. Also, try to keep hygiene products like shampoo out of your ears."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To prevent wax build-up, WebMD also suggests that you ", React.createElement(_components.strong, null, "keep your ears dry"), ". When you are rinsing your hair, for instance, angle your head to ensure the water flows away from your ears. After swimming, shake your head to remove any water from your ear canals. Of course, you can’t always avoid getting water in your ear canals, but you can reduce the chances of significant accumulations of wax. So make sure you dry your ears with the corner of a towel or a hair dryer."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-is-excess-earwax-removed",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-is-excess-earwax-removed",
    "aria-label": "how is excess earwax removed permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How is excess earwax removed?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the NHS website, there are four main treatments offered to patients with a severe earwax accumulation."), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Eardrops — This is the most common treatment for earwax build-ups. The drops are applied several times a day until the wax softens and falls out on its own."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear irrigation — Water is pushed into the ear with an electric pump to flush the wax out."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Aural toilet — Wax is “scraped” from the ear canal with a thin instrument."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Microsuction — A special suction device is used to suck the wax from the ear canal."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "interesting-facts-about-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#interesting-facts-about-earwax",
    "aria-label": "interesting facts about earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Interesting facts about earwax"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "It cleans our ears — ", React.createElement(_components.strong, null, "Earwax keeps our ears clean"), ", so there’s usually no need to use anything else. The simple act of talking and chewing ensures wax moves from the inner ear to the opening."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Don’t take wax removal into your own hands — Trying to fish wax out yourself can make the problem worse."), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Never use ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/ear-candling/",
    className: "c-md-a"
  }, "“candling” as a wax removal method"), " — So-called “candling” involves the use of a long, cone-shaped candle, which is inserted into the ear and lit. This method is not effective and is very dangerous."), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-diseases-related-to-the-build-up-of-earwax",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-diseases-related-to-the-build-up-of-earwax",
    "aria-label": "what are the diseases related to the build up of earwax permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the diseases related to the build-up of earwax?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Too much earwax in your ear canal can cause one or all of the symptoms listed above, including varying degrees of hearing loss, tinnitus, earache and vertigo. If left untreated for a period of time, impacted wax can also lead to infections."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to the Irish Health Service Executive, ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-externa/",
    className: "c-md-a"
  }, "otitis externa (inflammation of the external ear canal)"), " is a relatively rare condition that can be caused by excess earwax. ", React.createElement(_components.a, {
    href: "/resources/hearing-loss/otitis-media/",
    className: "c-md-a"
  }, "Otitis media is a middle ear infection"), " that can sometimes result from large accumulations of impacted earwax. In very serious cases, a perforated eardrum or damage to the external auditory meatus can occur."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Before you do anything, ask your doctor: how should you remove excess earwax safely? You want to make sure you use the right methods and avoid doing anything that can make the problem worse. The treatment you are offered will depend on several factors, such as the amount of wax in your ear, the type of wax, and how deep it lies inside your ear canal."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In most cases, the body does a very good job of expelling excess earwax on its own. However, when the situation becomes more serious, medical intervention may be required in order to stop hearing loss and a range of other related problems."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
